import React from "react"
import Layout from "../components/layout"
import Terms from "../components/terms/terms"
import SEO from "../components/seo"

const TermsPage = () => (
  <Layout>
    <SEO title="Terms &amp; Conditions Of Techvizon" pathname="/terms" description="Terms &amp; Conditions for Techvizon Private Limited, these terms and conditions outline the rules and regulations for the use of techvizon.com's Website" />
    <Terms/>
  </Layout>
)

export default TermsPage
